import { Box, FormControl, Grid, Link, Typography, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import useProjectId from "../hooks/useProjectId";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  selectConnectivity,
  selectGitHubEnabled,
  setProjectID,
  hasGitHubIntegration,
  selectProjectID,
} from "../features/githubSlice";
import { appReflector } from "../utils/next_urls";
import { OverviewBox } from "../components/OverviewBox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchIntegrations, selectIntegrations, Integration } from "../features/integrationsSlice";
import { createProposal } from "../features/proposalListSlice";
import { fetchRepoList, selectRepos, updateRepoStatus } from "../features/repoListSlice";
import { SourceRepo } from "../features/repoDetailSlice";
import { CodeRepo } from "../components/CodeRepo";
import { DialogTriggerCategoryAnalysis } from "../components/DialogTriggerCategoryAnalysis";

export const CodeAnalysisStart = () => {
  const connectivity = useAppSelector(selectConnectivity);
  const githubEnabled = useAppSelector(selectGitHubEnabled);
  const integrations = useAppSelector(selectIntegrations);
  const repos = useAppSelector(selectRepos);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectId = useAppSelector(selectProjectID);

  const [selectedRepo, setSelectedRepo] = useState<SourceRepo | null>();

  const onRepoChange = (event: SelectChangeEvent<string>) => {
    const repoName = event.target.value;
    const repo = repos?.find((r: SourceRepo) => r.displayName === repoName);
    if (repo) {
      setSelectedRepo(repo);
    }
  };

  useProjectId((projectId: string) => {
    dispatch(hasGitHubIntegration({ projectId }));
    dispatch(fetchIntegrations({ projectId }));
    dispatch(setProjectID(projectId));
    dispatch(fetchRepoList({ projectId: projectId }));
  });

  useEffect(() => {
    if (repos !== null && repos.length > 0) {
      setSelectedRepo(repos[0]);
    }
  }, [repos]);

  const githubNextUrl = () => {
    const proto = window.location.protocol;
    const host = window.location.hostname;
    const port = window.location.port;
    return encodeURIComponent(`${proto}//${host}:${port}/github-complete-install/codeanalysis`);
  };

  const installGitHub = async () => {
    window.location.href = `${appReflector()}/github/install?next=${githubNextUrl()}`;
  };

  const saveRepo = async () => {
    if (selectedRepo) {
      await dispatch(updateRepoStatus({ projectId: projectId, repoId: selectedRepo.id, active: true }));
      await dispatch(
        createProposal({
          projectId: projectId,
          category: { upgradeCategory: "outdated" },
          repoId: selectedRepo.id,
          pullRequest: true,
        }),
      );
      navigate("/proposals");
    } else {
      navigate("/proposals");
    }
  };

  useEffect(() => {
    document.title = "Get Started - EdgeBit";
  }, []);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}

      <Box sx={{ background: "#132243", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 999 }}>
        <Typography variant="h5" sx={{ marginTop: "30px", textAlign: "center", color: "#fff" }}>
          Get Started with EdgeBit
        </Typography>
        <Typography
          variant="body1"
          sx={{ margin: "10px auto 30px auto", maxWidth: "400px", textAlign: "center", color: "#fff" }}
        >
          AI Dependency Autofix continually keeps dependencies up to date with static analysis, reachability and AI.
        </Typography>
        <Grid container spacing={2} sx={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto" }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBox
              title="1. Connect GitHub"
              subtitle="Grant access to at least one repo"
              align="center"
              sx={{ minHeight: "355px" }}
              disabled={githubEnabled}
            >
              <Box sx={{ textAlign: "center", minHeight: "240px" }}>
                {githubEnabled && (
                  <Box
                    sx={{
                      border: "2px solid #88DC87",
                      maxWidth: "300px",
                      margin: "15px auto 0 auto",
                      padding: "20px 15px",
                      borderRadius: "3px",
                    }}
                  >
                    <CheckCircleOutlineIcon sx={{ color: "#88DC87", fontSize: "40px", display: "inline-block" }} />
                    <Box sx={{ fontWeight: "600", marginBottom: "10px" }}>Connected</Box>
                    <Box>
                      {integrations?.map((i: Integration) => (
                        <Box key={i.id}>
                          <Box
                            component="img"
                            sx={{
                              display: "inline-block",
                              borderRadius: "3px",
                              width: "20px",
                              height: "20px",
                              background: "#f5f5f5",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                            src={i.avatarUrl}
                          />
                          {i.account}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
                {!githubEnabled && (
                  <>
                    <img
                      src={"../diagram-ephemeral-vm.svg"}
                      style={{
                        maxHeight: "130px",
                        maxWidth: "100%",
                        margin: "15px auto",
                        display: "block",
                        boxShadow: "1px 1px 8px rgb(0 0 0 / 10%)",
                        border: "1px solid #ddd",
                      }}
                      alt=""
                    />
                    <Box sx={{ fontSize: "10px" }}>
                      Your code is always{" "}
                      <Link href="https://edgebit.io/docs/0.x/codeanalysis-how-it-works/">
                        processed in ephemeral VMs
                      </Link>{" "}
                      and never stored.
                    </Box>
                    <EdgeBitPrimaryButton
                      type="submit"
                      variant="outlined"
                      size="medium"
                      sx={{ marginTop: "20px", marginBottom: "0px" }}
                      onClick={installGitHub}
                    >
                      Authorize App
                    </EdgeBitPrimaryButton>
                  </>
                )}
              </Box>
            </OverviewBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBox
              title="2. Select Repositories"
              subtitle="Continually manage dependency upgrades"
              align="center"
              disabled={!githubEnabled}
              sx={{ minHeight: "355px" }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Box>
                  <FormControl
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      backgroundColor: "#fff",
                      textAlign: "left",
                      marginTop: "15px",
                    }}
                  >
                    <Select
                      name="repo-name"
                      displayEmpty
                      onChange={onRepoChange}
                      disabled={repos === null}
                      value={selectedRepo ? selectedRepo.displayName : "Loading..."}
                    >
                      {repos?.map((r: SourceRepo) => (
                        <MenuItem key={r.displayName} value={r.displayName}>
                          <CodeRepo repo={r} />
                        </MenuItem>
                      ))}
                      {!selectedRepo && <MenuItem value="Loading...">Loading...</MenuItem>}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ marginTop: "15px", fontSize: "14px" }}>
                  EdgeBit will propose dependency upgrades for Javascript or TypeScript within this repository.
                </Box>
                <EdgeBitPrimaryButton
                  type="submit"
                  variant="outlined"
                  size="medium"
                  sx={{ marginTop: "20px", marginBottom: "0px" }}
                  onClick={saveRepo}
                >
                  Save & Start
                </EdgeBitPrimaryButton>
              </Box>
            </OverviewBox>
          </Grid>
        </Grid>
        <Box sx={{ position: "absolute", bottom: "30px", textAlign: "center", width: "100%", background: "#132243" }}>
          <Link component={NavLink} to={"/"}>
            Skip onboarding, take me to the app
          </Link>
        </Box>
      </Box>

      <DialogTriggerCategoryAnalysis />
    </BodyWrapperProjectScoped>
  );
};
