import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { ProjectContext } from "../App";
import { ComponentIssueState, ComponentIssuesBySearchSummary } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { OverviewIssueTable } from "../components/OverviewIssueTable";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { OverviewBox } from "../components/OverviewBox";
import { ComponentIssue } from "../features/componentDetailSlice";

export const Search = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [searchIssues, setSearchIssues] = useState<ComponentIssue[] | undefined>([]);
  const [summary, setSummary] = useState<ComponentIssuesBySearchSummary | undefined>(undefined);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("packageSubstring");
  const [searchStates, setSearchStates] = useState<ComponentIssueState[]>([ComponentIssueState.OPEN]);
  const project = useContext(ProjectContext);

  let refreshSearch = () => {
    if (!project) {
      return;
    }

    if (search.length <= 2) {
      return;
    }

    setSearchIssues(undefined);
    switch (searchType) {
      case "packageSubstring":
        client
          .listComponentIssuesByPackageSubstringForProject({
            projectId: project.id,
            states: searchStates,
            search: search,
          })
          .then(
            (res) => {
              setSummary(res.summary);
              const formattedIssues = res.items.map((specificItem) => {
                return {
                  ...specificItem,
                  createdAt: specificItem.createdAt
                    ? new Date(Number(specificItem.createdAt.seconds) * 1000).toISOString()
                    : undefined,
                  updatedAt: specificItem.updatedAt
                    ? new Date(Number(specificItem.updatedAt.seconds) * 1000).toISOString()
                    : undefined,
                  dueAt: specificItem.dueAt
                    ? new Date(Number(specificItem.dueAt.seconds) * 1000).toISOString()
                    : undefined,
                  latestProposalId: specificItem.latestProposalId,
                };
              });
              setSearchIssues(formattedIssues);
            },
            (err) => {
              console.log(err);
              setConnectivity(false);
            },
          );
        break;
      case "vulnId":
        client
          .listComponentIssuesByVulnIDForProject({
            projectId: project.id,
            states: searchStates,
            search: search,
          })
          .then(
            (res) => {
              setSummary(res.summary);
              const formattedIssues = res.items.map((specificItem) => {
                return {
                  ...specificItem,
                  createdAt: specificItem.createdAt
                    ? new Date(Number(specificItem.createdAt.seconds) * 1000).toISOString()
                    : undefined,
                  updatedAt: specificItem.updatedAt
                    ? new Date(Number(specificItem.updatedAt.seconds) * 1000).toISOString()
                    : undefined,
                  dueAt: specificItem.dueAt
                    ? new Date(Number(specificItem.dueAt.seconds) * 1000).toISOString()
                    : undefined,
                  latestProposalId: specificItem.latestProposalId,
                };
              });
              setSearchIssues(formattedIssues);
            },
            (err) => {
              console.log(err);
              setConnectivity(false);
            },
          );
        break;
      default:
        return;
    }
  };

  let updateSearchStates = (states: ComponentIssueState[]) => {
    let updatedStates = searchStates;
    for (let state of states) {
      if (updatedStates.includes(state)) {
        updatedStates = updatedStates.filter((s) => s !== state);
      } else {
        updatedStates = [...updatedStates, state];
      }
    }
    setSearchStates(updatedStates);
  };

  // Set title
  useEffect(() => {
    document.title = "Search - EdgeBit";
  }, []);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        Search for Issues
      </Typography>
      <Select
        value={searchType}
        onChange={(e) => setSearchType(e.target.value)}
        sx={{ marginRight: "10px", width: "170px" }}
        size="small"
      >
        <MenuItem value="packageSubstring">By Package</MenuItem>
        <MenuItem value="vulnId">By Vulnerability</MenuItem>
      </Select>
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            refreshSearch();
            ev.preventDefault();
          }
        }}
        size="small"
        sx={{ height: "40px", width: "300px", marginRight: "10px" }}
        placeholder={searchType === "packageSubstring" ? "Search by package name..." : "Search by vulnerability ID..."}
        autoFocus={true}
      />
      <EdgeBitPrimaryButton onClick={refreshSearch} sx={{ height: "40px", verticalAlign: "top", marginRight: "20px" }}>
        Search
      </EdgeBitPrimaryButton>

      <FormGroup sx={{ display: "inline-block", verticalAlign: "top" }}>
        <Box sx={{ verticalAlign: "middle", display: "inline-block", marginRight: "10px" }}>States:</Box>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={searchStates.includes(ComponentIssueState.OPEN)}
              onChange={() => updateSearchStates([ComponentIssueState.OPEN])}
            />
          }
          label="Open"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={
                searchStates.includes(ComponentIssueState.IGNORED) && searchStates.includes(ComponentIssueState.IGNORED)
              }
              onChange={() => updateSearchStates([ComponentIssueState.IGNORED, ComponentIssueState.RESOLVED])}
            />
          }
          label="Ignored & Resolved"
        />
      </FormGroup>

      {summary && (
        <OverviewBox title="Real-time Summary" sx={{ marginTop: "25px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                Components
              </Typography>
              <Box sx={{ fontSize: "14px" }}>
                {summary?.components} components have issues related to this dependency
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                Real-time
              </Typography>
              <Box sx={{ fontSize: "14px" }}>
                {summary?.activeWorkloads} workloads running on {summary?.machines} machines
              </Box>
            </Grid>
          </Grid>
        </OverviewBox>
      )}

      <OverviewIssueTable issues={searchIssues} defaultFilterCVE="cveAll" />
    </BodyWrapperProjectScoped>
  );
};
