import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { MouseEvent, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Component, ComponentIssue, fetchComponent } from "../features/componentDetailSlice";
import { Project, selectCurrentProject } from "../features/projectSlice";
import {
  openSpecificPackageModal,
  searchForPackageByIssuePackageName,
  selectProjectID,
  setComponentIssueID,
  setTargetCategory,
  setTargetComponent,
  setTargetOptions,
} from "../features/proposalListSlice";
import { fetchRepoList } from "../features/repoListSlice";
import useProjectId from "../hooks/useProjectId";
import {
  ComponentIssueSeverity,
  ComponentIssueState,
  VulnFixState,
  VulnSeverity,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import { getJiraIssueID, getJiraIssueLink } from "../utils/jira";
import { DialogTriggerPackageAnalysis } from "./DialogTriggerPackageAnalysis";
import FormattedTimestamp from "./FormattedTimestamp";
import { IssueModificationMenu } from "./IssueModifications";
import { PackageType } from "./PackageType";
import RelativeTimestamp from "./RelativeTimestamp";
import VulnChip from "./VulnChip";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";
import { FeatureFlagGate } from "./FeatureFlagGate";

export interface ComponentIssuesTableProps {
  component: Component;
  issues: ComponentIssue[] | null;
  handleSuppressionModal: (id: string | undefined) => void;
  handleIgnoreModal: (issue: ComponentIssue | undefined) => void;
  jiraOrgHostname: string | undefined;
}

const stateNames: Record<ComponentIssueState, string> = {
  [ComponentIssueState.UNSPECIFIED]: "Unknown", // This should never happen
  [ComponentIssueState.IGNORED]: "Ignored",
  [ComponentIssueState.OPEN]: "Open",
  [ComponentIssueState.RESOLVED]: "Resolved",
};

const borderColor: Record<ComponentIssueSeverity, string> = {
  [ComponentIssueSeverity.UNSPECIFIED]: "#ccc",
  [ComponentIssueSeverity.NEGLIGIBLE]: "#4FD35D",
  [ComponentIssueSeverity.LOW]: "#4FD35D",
  [ComponentIssueSeverity.MEDIUM]: "#FF7A00",
  [ComponentIssueSeverity.HIGH]: "#FF0000",
  [ComponentIssueSeverity.CRITICAL]: "#FF0000",
};

const fixState: Record<VulnFixState, string> = {
  0: "Unspecified",
  1: "Not fixed yet",
  2: "Not fixed yet", // This is really "Maintainer won't fix" but this makes no sense to users
  3: "Fixed, upgrade",
};

const fixStatePriority: Record<VulnFixState, number> = {
  [VulnFixState.UNSPECIFIED]: 0, // Unspecified
  [VulnFixState.WONTFIX]: 1, // Maintainer won't fix
  [VulnFixState.NOTFIXED]: 2, // Not fixed yet
  [VulnFixState.FIXED]: 3, // Fixed, upgrade
};

function showFixState(
  currentProject: Project | null,
  component: Component,
  issue: ComponentIssue,
  openModal: ((pkgName: string) => void) | void,
): ReactElement {
  const box = (body: any) => <Box sx={{ display: "inline-block", fontSize: "13px" }}>{body}</Box>;
  const blueButton = (action: (name: string) => void, body: any) => (
    <EdgeBitPrimaryButton
      onClick={() => action(issue?.packageName)}
      sx={{ fontSize: "13px", padding: "2px 10px", marginTop: "-5px" }}
    >
      {body}
    </EdgeBitPrimaryButton>
  );
  const greenButton = (url: string, body: any) => (
    <NavLink to={url}>
      <Button
        variant="contained"
        color="success"
        sx={{ fontSize: "13px", fontWeight: 800, padding: "2px 10px", marginTop: "-5px", boxShadow: "none" }}
      >
        {body}
      </Button>
    </NavLink>
  );

  if (issue?.latestProposalId) {
    if (component.sourceRepository?.kind.case === "sourceRepoDetails") {
      return greenButton(
        "/repos/" +
          component.sourceRepository.kind.value.id +
          "/proposals/" +
          issue.latestProposalId +
          (currentProject ? "?project=" + currentProject.name : ""),
        "View Proposed fix",
      );
    } else {
      return box("Proposal not found");
    }
  } else if (issue?.details.value?.fixState) {
    var message = fixState[issue.details.value.fixState];
    if (issue.details.value.fixVersions.length && openModal) {
      return blueButton(openModal, message + " to " + issue.details.value.fixVersions.toString() + " with Autofix");
    } else {
      return box(message);
    }
  } else {
    return box("Fix state not specified");
  }
}

function showIgnoredState(labels: { [key: string]: string }) {
  var message = "";
  if (labels["edgebit.io/managed-by"] === "edgebit-policy-engine") {
    // Turn the policy labels into a human readable message
    if (labels["edgebit.io/policy-engine/suppressed-by"]) {
      var policy_type = labels["edgebit.io/policy-engine/suppressed-by"].split(":")[0];
      var policy_target = labels["edgebit.io/policy-engine/suppressed-by"].split(":")[1];
      switch (policy_type) {
        case "policy_ignore_severity_threshold":
          message = "Suppressed due to policy: ";
          message += "ignore " + policy_target + " and below";
          break;
        case "policy_dormant_package":
          message = "Suppressed due to policy: ";
          if (policy_target.includes("set_")) {
            message += "dormant → " + policy_target.replace("set_", "");
          } else if (policy_target.includes("drop_")) {
            message += "dormant → drop " + policy_target.replace("drop_", "") + " levels";
          } else {
            message += "dormant → " + policy_target;
          }
          break;
        default:
          message += policy_target;
          break;
      }
    }
  } else {
    message = "Suppressed manually";
  }
  return message;
}

function sortIssues(a: ComponentIssue, b: ComponentIssue) {
  if (a.state === b.state && a.state === ComponentIssueState.RESOLVED && a.updatedAt && b.updatedAt) {
    // If resolved, sort by closed date descending
    return a.updatedAt < b.updatedAt ? 1 : -1;
  }
  if (a.severity !== b.severity) {
    // Severity always wins
    return a.severity < b.severity ? 1 : -1;
  }
  if (
    a.details?.value?.fixState !== undefined &&
    b.details?.value?.fixState !== undefined &&
    a.details.value.fixState !== b.details.value.fixState
  ) {
    // If severity is equal, favor fixed vulns
    return fixStatePriority[a.details.value.fixState] < fixStatePriority[b.details.value.fixState] ? 1 : -1;
  }
  if (
    a.details &&
    b.details &&
    a.details.value?.fixVersions.length &&
    b.details.value?.fixVersions.length &&
    a.details.value?.fixVersions.length !== b.details.value?.fixVersions.length
  ) {
    // If severity and fix is equal, favor one with a fix version
    return a.details.value?.fixVersions.length > b.details.value?.fixVersions.length ? 1 : -1;
  }
  return a.id < b.id ? 1 : -1;
}

function IssueContainer(props: {
  issue: ComponentIssue;
  component: Component;
  handleModificationClick: any;
  modificationMenuOpen: any;
  jiraOrgHostname: string | undefined;
}) {
  var issue = props.issue;
  var vuln = issue.details.value || undefined;
  const { componentId } = useParams();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectProjectID);
  const currentProject = useSelector(selectCurrentProject);

  var currentBorderColor;
  if (issue.state === ComponentIssueState.OPEN) {
    // Set border color to severity because issue is open
    currentBorderColor = borderColor[issue.severity];
  } else {
    // Do not have a border on fixed or ignored issues
    currentBorderColor = "transparent";
  }

  const handleSpecificPackageModalOpen = async (pkgName: string) => {
    await dispatch(
      searchForPackageByIssuePackageName({
        projectId,
        packageName: pkgName,
        componentId: componentId,
      }),
    );
    dispatch(setTargetCategory({ upgradeCategory: "requested" }));
    dispatch(setTargetOptions({ subDirectory: props.component.subdir }));
    dispatch(setTargetComponent(props.component));
    dispatch(setComponentIssueID(issue.id));

    dispatch(openSpecificPackageModal());
  };

  const vulnSeverityString = VulnSeverity[issue.severity].toString();
  const vulnSeverityStringSentenceCase =
    vulnSeverityString.charAt(0).toUpperCase() + vulnSeverityString.slice(1).toLowerCase();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        marginBottom: "20px",
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        border: "1px solid #ddd",
        borderBottom: "0px",
      }}
    >
      <Box sx={{ flexDirection: "column", flexGrow: 1 }}>
        <Box
          sx={{
            borderBottom: "1px solid #ccc",
            minHeight: "40px",
            verticalAlign: "middle",
            lineHeight: { xs: "40px", md: "40px" },
            paddingLeft: "11px",
            borderLeft: "4px solid " + currentBorderColor,
          }}
        >
          <Box sx={{ display: "inline-block", paddingRight: "15px" }}>
            <Link component={NavLink} to={"/components/" + componentId + "/issues/" + issue.id}>
              #{issue.id}
            </Link>
          </Box>
          <PackageType type={issue.packageType} name={false} />
          <Box sx={{ display: "inline-block", paddingRight: "20px" }}>{issue.packageName}</Box>
          {issue.state === ComponentIssueState.OPEN &&
            issue.packageType === "npm" &&
            vuln?.fixState === VulnFixState.FIXED && (
              <FeatureFlagGate flag="code-analysis" fallback={showFixState(currentProject, props.component, issue)}>
                {showFixState(currentProject, props.component, issue, handleSpecificPackageModalOpen)}
              </FeatureFlagGate>
            )}
          <Button
            id={"modification-button-" + issue.id}
            aria-controls={props.modificationMenuOpen ? "issue-modification-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={props.modificationMenuOpen ? "true" : undefined}
            onClick={props.handleModificationClick}
            sx={{ minWidth: "auto", float: "right", padding: "10px 10px 0 0" }}
          >
            <SettingsIcon sx={{ verticalAlign: "middle", fontSize: "18px", color: "#666" }}></SettingsIcon>
          </Button>
        </Box>
        <Box sx={{ padding: "10px 15px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "15px",
              paddingBottom: "10px",
              fontSize: "14px",
            }}
          >
            <Box>{stateNames[issue.state]} Vulnerability</Box>
            <Box>
              <VulnChip preset={VulnSeverity[issue.severity]} innerText={vulnSeverityStringSentenceCase}></VulnChip>
            </Box>
            <Box>
              {!vuln ? (
                <Box>
                  <Typography variant="body2" gutterBottom>
                    Vuln ID Missing
                  </Typography>
                </Box>
              ) : (
                <Link href={vuln?.references[0].url}>{vuln?.id}</Link>
              )}
            </Box>
            <Box>
              {(issue.state === ComponentIssueState.IGNORED || issue.severity !== issue.upstreamSeverity) && (
                <Tooltip title={JSON.stringify(issue.labels)}>
                  <Box
                    sx={{
                      display: "inline-block",
                      paddingLeft: { xs: "0px", md: "10px" },
                      fontSize: "14px",
                    }}
                  >
                    {showIgnoredState(issue.labels)}
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box sx={{ fontSize: "12px" }}>
            {vuln && vuln.description ? vuln.description : <Box sx={{ color: "#999" }}>No description included</Box>}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          borderLeft: { xs: "0px", md: "1px solid #ccc" },
          padding: "0 15px",
          minWidth: "220px",
          minHeight: { xs: "auto", md: "175px" },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
          First Observed
        </Typography>
        <Box sx={{ fontSize: "14px", marginBottom: "10px" }}>
          <FormattedTimestamp timestamp={issue.createdAt} />
        </Box>
        {issue.dueAt && (
          <>
            {issue.state === ComponentIssueState.IGNORED || issue.state === ComponentIssueState.RESOLVED ? (
              <>
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  Fixed On Date
                </Typography>
                <Box sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <FormattedTimestamp timestamp={issue?.updatedAt} />
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  SLA
                </Typography>
                <Box sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <RelativeTimestamp timestamp={issue.dueAt} />
                </Box>
              </>
            )}
            <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
              Fix By Date
            </Typography>
            <Box sx={{ fontSize: "14px", marginBottom: "10px" }}>
              <FormattedTimestamp timestamp={issue.dueAt} />
            </Box>
          </>
        )}
        {!issue.dueAt && (
          <>
            {issue.state === ComponentIssueState.IGNORED || issue.state === ComponentIssueState.RESOLVED ? (
              <>
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  Fixed On Date
                </Typography>
                <Box sx={{ fontSize: "14px", marginBottom: "10px" }}>
                  <FormattedTimestamp timestamp={issue?.updatedAt} />
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  SLA
                </Typography>
                <Tooltip title={"Configure SLA policy per Project"}>
                  <Box
                    sx={{
                      fontSize: "14px",
                      marginBottom: "10px",
                      color: "#999",
                      display: "inline-block",
                    }}
                  >
                    No SLA set
                  </Box>
                </Tooltip>
              </>
            )}
          </>
        )}
        {props.jiraOrgHostname && getJiraIssueID(issue) !== "" && (
          <>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>
              Jira Issue
            </Typography>
            <Box sx={{ fontSize: "14px", marginBottom: "15px" }}>
              <Link href={getJiraIssueLink(props.jiraOrgHostname, getJiraIssueID(issue))}>{getJiraIssueID(issue)}</Link>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export function ComponentIssuesTable(props: ComponentIssuesTableProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const modificationMenuOpen = Boolean(anchorEl);
  const [issue, setIssue] = useState<ComponentIssue | undefined>();
  const dispatch = useAppDispatch();
  const { componentId } = useParams();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleModificationMenuClose = () => {
    setAnchorEl(null);
  };
  // const handleModifySuppression = () => {
  //   props.handleSuppressionModal(anchorEl?.dataset.sbomid)
  //   setAnchorEl(null);
  // };
  const handleIgnore = (id: string | undefined) => {
    props.handleIgnoreModal(issue);
    setAnchorEl(null);
  };

  useProjectId((projectId: string) => {
    dispatch(fetchComponent({ projectId: projectId, componentId: componentId ? componentId : "" }));
    dispatch(fetchRepoList({ projectId: projectId }));
  });

  const sortedIssues = props.issues ? [...props.issues].sort(sortIssues) : [];

  return (
    <Container sx={{ marginTop: "15px", marginBottom: "100vh" }} disableGutters>
      <IssueModificationMenu
        anchorEl={anchorEl}
        open={modificationMenuOpen}
        onClose={handleModificationMenuClose}
        handleIgnore={handleIgnore}
        issue={issue}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
      <DialogTriggerPackageAnalysis />

      {/* Show all non-negligible issues */}
      {sortedIssues?.map((issue) => (
        <IssueContainer
          key={issue.id}
          issue={issue}
          component={props.component}
          handleModificationClick={(event: MouseEvent<HTMLButtonElement>) => {
            handleClick(event);
            setIssue(issue);
          }}
          modificationMenuOpen={modificationMenuOpen}
          jiraOrgHostname={props.jiraOrgHostname}
        />
      ))}

      {/* Empty state */}
      {props.issues?.length === 0 && (
        <Box
          sx={{
            display: "flex",
            marginBottom: "20px",
            borderRadius: "4px",
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            border: "1px solid #ddd",
            borderBottom: "0px",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              display: "block",
              justifyContent: "center",
              alignItems: "center",
              width: "550px",
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
              You don't have issues matching this state
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
              New components require a few minutes to identify issues
            </Typography>
          </Box>
        </Box>
      )}

      {/* Loading state */}
      {props.issues === null && (
        <Box
          sx={{
            display: "flex",
            marginBottom: "20px",
            borderRadius: "4px",
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            border: "1px solid #ddd",
            borderBottom: "0px",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              display: "block",
              justifyContent: "center",
              alignItems: "center",
              width: "400px",
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ display: "block", width: "400px" }}>
              Loading issues...
            </Typography>
            <LinearProgress
              sx={{
                width: "200px",
                marginTop: "3px",
                marginRight: "auto",
                marginBottom: "20px",
                marginLeft: "auto",
              }}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
}
