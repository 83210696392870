import { Typography, Switch, SwitchProps, FormControl, FormGroup, FormControlLabel } from "@mui/material";

export interface SettingsToggleProps {
  id: string;
  checked?: boolean;
  label: string;
  onChange: SwitchProps["onChange"];
  helpText: string;
}

export default function SettingsToggle(props: SettingsToggleProps) {
  return (
    <>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="end"
            control={<Switch color="primary" checked={props.checked} onChange={props.onChange} />}
            label={props.label}
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
      <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
        {props.helpText}
      </Typography>
    </>
  );
}
