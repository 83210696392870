import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { ChangeEvent } from "react";
import { ComponentIssueSeverity, SBOMGenerationSource } from "../pb/edgebit/platform/v1alpha/platform_pb";
import SettingsField from "./SettingsField";
import SettingsToggle from "./SettingsToggle";
import { CodeRepo } from "./CodeRepo";
import { SourceRepo } from "../features/repoDetailSlice";

export interface ComponentSettingsData {
  displayName?: string;
  name?: string;
  autoUpdateName?: boolean;
  labelString?: string;
  availSourceRepositories: SourceRepo[];
  selectedSourceRepository?: string;
  customSourceRepository?: string;
  machineQueryString?: string;
  defaultTagName?: string;
  policyDormant?: number;
  policyIgnoreSeverityThreshold?: number;
  availSBOMSources?: SBOMGenerationSource[];
  selectedSBOMSource?: SBOMGenerationSource;
  subdir?: string;
  settings: {
    autoInventory: {
      periodic: {
        enabled: boolean;
      };
    };
    autoProposal: {
      enabled: boolean;
      legacyPeerDeps: boolean;
    };
  };
}

export function defaultComponentSettingsData(): ComponentSettingsData {
  return {
    availSourceRepositories: [],
    settings: {
      autoInventory: {
        periodic: {
          enabled: false,
        },
      },
      autoProposal: {
        enabled: false,
        legacyPeerDeps: false,
      },
    },
  };
}

interface ComponentSettingsProps {
  data: ComponentSettingsData;
  showAdvanced?: boolean;
  onChange: (data: ComponentSettingsData) => void;
}

function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/[\W_]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

export const sbomSourceStrings = new Map<SBOMGenerationSource, string>([
  [SBOMGenerationSource.SBOM_GENERATION_SOURCE_UNSPECIFIED, "don't generate"],
  [SBOMGenerationSource.SBOM_GENERATION_SOURCE_SOURCE, "from source code"],
  [SBOMGenerationSource.SBOM_GENERATION_SOURCE_BUILD, "from container build"],
]);

function sbomSources(sources: SBOMGenerationSource[] | undefined): object {
  if (sources === undefined) {
    return {};
  } else {
    const es = sources.map((s) => [s.toString(), sbomSourceStrings.get(s)]);
    return Object.fromEntries(es);
  }
}

// Parse a string of labels, of the form:
//  "key1=value1, key2=value2"
export function parseLabelString(text: string): { [key: string]: string } {
  const obj: { [key: string]: string } = {};

  if (text.trim() === "") {
    return obj;
  }

  const pairs = text.split(",");

  for (const pair of pairs) {
    const [key, value] = pair.trim().split("=");
    obj[key] = value || "";
  }

  return obj;
}

export function generateLabelString(labels: { [key: string]: string }): string {
  const pairs = [];

  for (const [key, value] of Object.entries(labels)) {
    pairs.push(`${key}=${value}`);
  }

  return pairs.join(", ");
}

interface SettingsDropdownProps {
  id: string;
  value?: string;
  label: string;
  onChange: SelectProps["onChange"];
  helpText: string;
  show?: boolean;
  values?: object;
  suggested?: string;
  capitalized?: boolean;
  children?: any;
}

function SettingsDropdown(props: SettingsDropdownProps) {
  // Hide only when show is explicitly set to false
  if (props.show === false) {
    return null;
  }

  const caps = props.capitalized !== false;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
        <Box sx={{ marginTop: "6px" }}>{props.label}</Box>
      </Grid>
      <Grid item xs={8} sm={8} md={6} lg={6}>
        <Select
          labelId="demo-select-small-label"
          id={props.id}
          size="small"
          value={props.value}
          onChange={props.onChange}
          sx={{ width: "100%", maxWidth: "400px", textTransform: caps ? "capitalize" : undefined }}
        >
          {props.children
            ? props.children
            : props.values &&
              Object.entries(props.values)
                .filter(([key, value]) => typeof value === "string")
                .map(([key, value]) => (
                  <MenuItem key={key} value={key} sx={caps ? { textTransform: "capitalize" } : {}}>
                    {value.toLowerCase()}
                    {key === props.suggested && " (suggested)"}
                  </MenuItem>
                ))}
        </Select>
        <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
          {props.helpText}
        </Typography>
      </Grid>
    </Grid>
  );
}

export function ComponentSettings(props: ComponentSettingsProps) {
  const handleDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newDisplayName = e.target.value;
    let newName = props.data.name;
    if (props.data.autoUpdateName) {
      newName = slugify(newDisplayName);
    }
    props.onChange({
      ...props.data,
      displayName: newDisplayName,
      name: newName,
    });
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    props.onChange({ ...props.data, name: newName, autoUpdateName: false });
  };

  return (
    <Container disableGutters>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: "18px",
          borderTop: "1px solid #ddd",
          marginTop: "20px",
          paddingTop: "20px",
          marginBottom: "20px",
        }}
      >
        Component Metadata
      </Typography>

      {/* Display Name */}
      <SettingsField
        id="displayName"
        value={props.data.displayName}
        label="Display Name:"
        onChange={handleDisplayNameChange}
        helpText="The name of the Component as it will appear in the UI."
      />

      {/* Name */}
      <SettingsField
        id="name"
        value={props.data.name}
        label="Name:"
        onChange={handleNameChange}
        helpText="Unique user-supplied identifier for this Component. Letters, numbers and dashes are allowed."
      />

      {/* Labels */}
      <SettingsField
        id="labels"
        value={props.data.labelString}
        label="Component Labels:"
        onChange={(e) => props.onChange({ ...props.data, labelString: e.target.value })}
        helpText="Key/value labels for use in organizing Components. Format: key1=value1, key2=value2"
        show={props.showAdvanced || false}
      />

      {props.showAdvanced && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: "18px",
              borderTop: "1px solid #ddd",
              marginTop: "20px",
              paddingTop: "20px",
            }}
          >
            Dependency Autofix
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ fontSize: "14px", color: "#999", marginBottom: "30px" }}>
            Control how Autofix analysis interacts with your codebase.
          </Typography>
        </>
      )}

      {/* Source Repository Combo */}
      <SettingsDropdown
        id="sourceRepositorySelected"
        value={props.data.selectedSourceRepository}
        label="Source Repository:"
        onChange={(e: SelectChangeEvent<unknown>) =>
          props.onChange({
            ...props.data,
            selectedSourceRepository: e.target.value as string,
          })
        }
        helpText="Code repository associated with the component."
        suggested="0"
        capitalized={false}
      >
        {props.data.availSourceRepositories.map((r) => (
          <MenuItem key={r.displayName} value={r.displayName}>
            <CodeRepo repo={r} />
          </MenuItem>
        ))}
        <MenuItem key="Repo URL" value="Repo URL" sx={{ textTransform: "capitalize" }}>
          Use Repo URL
        </MenuItem>
      </SettingsDropdown>

      {/* Source Repository */}
      <SettingsField
        id="sourceRepository"
        value={props.data.customSourceRepository}
        label="Source Repository URL:"
        onChange={(e) => props.onChange({ ...props.data, customSourceRepository: e.target.value })}
        helpText="The URL of the source repository for this Component."
        show={props.data.selectedSourceRepository === "Repo URL"}
      />

      {/* Project Root */}
      <SettingsField
        id="subdir"
        value={props.data.subdir}
        label="Project Root:"
        placeholder="Repository root directory"
        onChange={(e) => props.onChange({ ...props.data, subdir: e.target.value })}
        helpText="The subdirectory of the component within the source repository."
        show={props.data.selectedSourceRepository !== undefined && props.data.selectedSourceRepository !== "Repo URL"}
      />

      {/* Inventory */}
      {props.data.selectedSourceRepository !== undefined && props.data.selectedSourceRepository !== "Repo URL" && (
        <Grid container spacing={2} sx={{ marginBottom: "20px" }} display={{ all: "none" }}>
          <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
            <Box sx={{ marginTop: "6px" }}>Dependency Inventory:</Box>
          </Grid>
          <Grid item xs={8} sm={8} md={6} lg={6}>
            <SettingsToggle
              id="autoinventory"
              checked={props.data.settings.autoInventory.periodic.enabled}
              label="Automatically refresh inventory"
              onChange={(e) =>
                props.onChange({
                  ...props.data,
                  settings: {
                    ...props.data.settings,
                    autoInventory: {
                      ...props.data.settings.autoInventory,
                      periodic: { ...props.data.settings.autoInventory.periodic, enabled: e.target.checked },
                    },
                  },
                })
              }
              helpText="Periodically refresh the inventory of this component."
            />
          </Grid>
        </Grid>
      )}

      {/* Continous Proposals */}
      {props.data.selectedSourceRepository !== undefined && props.data.selectedSourceRepository !== "Repo URL" && (
        <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
          <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
            <Box sx={{ marginTop: "6px" }}>Continous Proposals:</Box>
          </Grid>
          <Grid item xs={8} sm={8} md={6} lg={6}>
            <SettingsToggle
              id="continuousProposals"
              checked={props.data.settings.autoProposal.enabled || false}
              label="Automatically generate Proposals"
              onChange={(e) =>
                props.onChange({
                  ...props.data,
                  settings: {
                    ...props.data.settings,
                    autoProposal: { ...props.data.settings.autoProposal, enabled: e.target.checked },
                  },
                })
              }
              helpText="Generate proposals and act on analysis results to keep your codebase secure."
            />
            {props.data.settings.autoProposal.enabled && (
              <Grid container spacing={2} sx={{ marginBottom: "10px", marginTop: "0px" }}>
                <Grid item xs={6} sm={6} md={6} lg={4} sx={{ fontWeight: "600" }}>
                  <Box sx={{ marginTop: "6px" }}>Peer Dependencies:</Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={8}>
                  <Select
                    name="repo-name"
                    displayEmpty
                    size="small"
                    onChange={(e) =>
                      props.onChange({
                        ...props.data,
                        settings: {
                          ...props.data.settings,
                          autoProposal: {
                            ...props.data.settings.autoProposal,
                            legacyPeerDeps: e.target.value === "relaxed",
                          },
                        },
                      })
                    }
                    value={props.data.settings.autoProposal.legacyPeerDeps ? "relaxed" : "null"}
                  >
                    <MenuItem value="null">Default</MenuItem>
                    <MenuItem value="relaxed">Relaxed</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {/* Generate a Pull Request */}
      <SettingsDropdown
        id="generatePr"
        value={props.data.selectedSBOMSource === undefined ? "" : props.data.selectedSBOMSource.toString()}
        label="Generate a Pull Request"
        helpText="Generate a Pull Request to create an SBOM for EdgeBit analysis"
        show={props.data.selectedSBOMSource !== undefined}
        onChange={(e: SelectChangeEvent<unknown>) =>
          props.onChange({
            ...props.data,
            selectedSBOMSource: parseInt(e.target.value as string),
          })
        }
        values={sbomSources(props.data.availSBOMSources)}
      />

      {props.showAdvanced && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: "18px",
              borderTop: "1px solid #ddd",
              marginTop: "20px",
              paddingTop: "20px",
            }}
          >
            Workload Relationships
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ fontSize: "14px", color: "#999", marginBottom: "30px" }}>
            Optionally tie this Component to Workloads as they are started and stopped to drive automation.
          </Typography>
        </>
      )}

      {/* Machine Query */}
      <SettingsField
        id="machineQuery"
        value={props.data.machineQueryString}
        label="Machine Label Query:"
        onChange={(e) => props.onChange({ ...props.data, machineQueryString: e.target.value })}
        helpText="Filter the machines running this component used to determine real-time context. This can be useful for targeting specific cloud accounts or ignoring test environments."
        show={props.showAdvanced || false}
      />

      {/* Default Tag */}
      <SettingsField
        id="defaultTag"
        value={props.data.defaultTagName}
        label="Default Tag:"
        onChange={(e) => props.onChange({ ...props.data, defaultTagName: e.target.value })}
        helpText="Name of the default tag to display, defaults to 'latest'."
        show={props.showAdvanced || false}
      />

      {props.showAdvanced && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: "18px",
              borderTop: "1px solid #ddd",
              marginTop: "20px",
              paddingTop: "20px",
            }}
          >
            Security Vulnerabilities
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ fontSize: "14px", color: "#999", marginBottom: "30px" }}>
            EdgeBit will use these settings inside of the Vulnerability Disclosure Report (VDR) and Vulnerability
            Exploitability Exchange (VEX) metadata.
          </Typography>
        </>
      )}

      {/* Ignore Threshold */}
      <SettingsDropdown
        id="ignore"
        value={props.data.policyIgnoreSeverityThreshold?.toString()}
        label="Ignore Threshold:"
        onChange={(e: SelectChangeEvent<unknown>) =>
          props.onChange({
            ...props.data,
            policyIgnoreSeverityThreshold: parseInt(e.target.value as string),
          })
        }
        helpText="Ignore all vulnerabilities at or below this severity level."
        show={props.showAdvanced || false}
        values={ComponentIssueSeverity}
        suggested="1"
      />

      {/* Dormant Suppression */}
      {props.showAdvanced && (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
            <Box sx={{ marginTop: "6px" }}>Dormant Dependencies:</Box>
          </Grid>
          <Grid item xs={8} sm={8} md={6} lg={6}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={props.data.policyDormant}
                name="radio-buttons-group"
                onChange={(e) =>
                  props.onChange({
                    ...props.data,
                    policyDormant: parseInt(e.target.value),
                  })
                }
              >
                <FormControlLabel value={5} control={<Radio />} label="Always suppress to Medium (Suggested)" />
                <FormControlLabel value={4} control={<Radio />} label="Always suppress to Low" />
                <FormControlLabel value={1} control={<Radio />} label="Ignore the vulnerabilities" />
                <FormControlLabel value={3} control={<Radio />} label="Drop 2 severity levels" />
                <FormControlLabel value={2} control={<Radio />} label="Drop 1 severity level" />
                <FormControlLabel value={0} control={<Radio />} label="Disable EdgeBit suppresions" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
