import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";
import {
  selectProjectID,
  setProjectID,
  setSearch,
  selectOpenPullRequest,
  setOpenPullRequest,
  setTargetCategory,
  createProposal,
  selectCreatingProposal,
  selectTargetCategory,
  selectUpgradeCategoryModalOpen,
  closeUpgradeCategoryModal,
  TargetCategory,
  setTargetOptions,
  TargetOptions,
  selectTargetOptions,
} from "../features/proposalListSlice";
import { Proposal } from "../features/repoDetailSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectRepos } from "../features/repoListSlice";
import { selectComponentList } from "../features/componentListSlice";
import useProjectId from "../hooks/useProjectId";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

interface DialogTriggerCategoryAnalysisProps {}

export function DialogTriggerCategoryAnalysis(props: DialogTriggerCategoryAnalysisProps) {
  const dispatch = useAppDispatch();
  const specificCategoryModalOpen = useAppSelector(selectUpgradeCategoryModalOpen);
  const openPullRequest = useAppSelector(selectOpenPullRequest);
  const repos = useAppSelector(selectRepos);
  const components = useAppSelector(selectComponentList);
  const projectId = useAppSelector(selectProjectID);
  const category = useAppSelector(selectTargetCategory);
  const options = useAppSelector(selectTargetOptions);
  const [componentId, setComponentId] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  useProjectId((projectId: string) => {
    dispatch(setProjectID(projectId));
  });

  const handlePullRequest = () => {
    dispatch(setOpenPullRequest(!openPullRequest));
  };

  const handleCategoryModalClose = () => {
    dispatch(closeUpgradeCategoryModal());
  };

  const handleStartCategoryAnalysis = async () => {
    dispatch(setSearch(""));
    let response: Proposal | undefined;
    const component = components?.find((c) => c.id === componentId);
    const repoId =
      component?.sourceRepository?.kind.case === "sourceRepoId" ? component?.sourceRepository?.kind.value : undefined;

    if (repoId && category) {
      response = await dispatch(
        createProposal({
          projectId: projectId,
          category: category,
          repoId: repoId,
          pullRequest: openPullRequest,
          options: options,
        }),
      ).unwrap();
    }
    dispatch(closeUpgradeCategoryModal());
    navigate("/proposals");
    if (response) {
      navigate(`/repos/${response.repoId}/proposals/${response.id}`);
    }
  };

  const handleTargetCategory = useCallback(
    (updatedProps: TargetCategory) => {
      let mergedProps = { ...category, ...updatedProps };
      dispatch(setTargetCategory(mergedProps));
    },
    [category, dispatch],
  );

  const handleBranchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTargetCategory({ branch: event.target.value });
  };

  const handleTargetOptions = useCallback(
    (updatedProps: TargetOptions) => {
      let mergedProps = { ...options, ...updatedProps };
      dispatch(setTargetOptions(mergedProps));
    },
    [options, dispatch],
  );

  const handlePeerDepChange = (event: SelectChangeEvent<any>) => {
    handleTargetOptions({ legacyPeerDeps: event.target.value === "relaxed" });
  };

  const handleSubDirectoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTargetOptions({ subDirectory: event.target.value });
  };

  const handleComponentChange = (event: SelectChangeEvent<any>) => {
    const id = event.target.value;
    setComponentId(id);

    const component = components?.find((c) => c.id === id);
    const repoId =
      component?.sourceRepository?.kind.case === "sourceRepoId" ? component?.sourceRepository?.kind.value : undefined;
    if (repoId) {
      let repo = repos?.find((repo) => repo.id === repoId);
      handleTargetCategory({ branch: repo?.targetBranch });
      handleTargetOptions({ subDirectory: component?.subdir, legacyPeerDeps: true });
    } else {
      handleTargetCategory({ branch: "" });
      handleTargetOptions({ subDirectory: "" });
    }
  };

  return (
    <Dialog open={specificCategoryModalOpen} onClose={handleCategoryModalClose} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title">Start Upgrade Analysis</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A proposal will be created to upgrade {category?.upgradeCategory} dependencies plus any required transitive
          updates.
        </DialogContentText>

        <Grid sx={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600", lineHeight: "40px" }}>
              <Box>Component:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={5}>
              <FormControl
                sx={{
                  width: "100%",
                  maxWidth: 300,
                  backgroundColor: "#fff",
                  textAlign: "left",
                }}
              >
                <Select
                  name="component-name"
                  displayEmpty
                  size="small"
                  onChange={handleComponentChange}
                  disabled={repos === null}
                  value={componentId ? componentId : "Loading..."}
                >
                  {components?.map((c) => (
                    <MenuItem key={c.displayName} value={c.id}>
                      {c.displayName}
                    </MenuItem>
                  ))}
                  {!components && <MenuItem value="Loading...">Loading...</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600", lineHeight: "40px", marginTop: "10px" }}>
              <Box>Branch:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={5}>
              <FormControl
                sx={{
                  width: "100%",
                  maxWidth: 150,
                  backgroundColor: "#fff",
                  textAlign: "left",
                  margin: "10px 0",
                }}
              >
                <TextField required label="" value={category?.branch} onChange={handleBranchChange} size="small" />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "0px" }}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600" }}>
              <Box>Category:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={5}>
              {category?.upgradeCategory}
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "10px" }}>
          <Accordion sx={{ boxShadow: "0", padding: "0", minHeight: "unset" }}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ padding: "0", color: "#6096FF", "& div": { margin: "0" } }}
            >
              Advanced Options
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{ fontWeight: "600", lineHeight: "40px", marginTop: "10px" }}
                >
                  <Box>Subdirectory Path:</Box>
                </Grid>
                <Grid item xs={8} sm={8} md={6} lg={5}>
                  <FormControl
                    sx={{
                      width: "100%",
                      maxWidth: 150,
                      backgroundColor: "#fff",
                      textAlign: "left",
                      margin: "10px 0",
                    }}
                  >
                    <TextField
                      label=""
                      value={options?.subDirectory ?? ""}
                      placeholder=""
                      onChange={handleSubDirectoryChange}
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{ fontWeight: "600", lineHeight: "40px", marginTop: "10px" }}
                >
                  <Box>Peer Dependencies:</Box>
                </Grid>
                <Grid item xs={8} sm={8} md={6} lg={5}>
                  <FormControl
                    sx={{
                      width: "100%",
                      maxWidth: 150,
                      backgroundColor: "#fff",
                      textAlign: "left",
                      margin: "10px 0",
                    }}
                  >
                    <Select
                      name="repo-name"
                      displayEmpty
                      size="small"
                      onChange={handlePeerDepChange}
                      value={options?.legacyPeerDeps ? "relaxed" : "null"}
                    >
                      <MenuItem value="null">Default</MenuItem>
                      <MenuItem value="relaxed">Relaxed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            borderTop: "1px solid #ddd",
            marginTop: "20px",
            paddingTop: "18px",
            marginBottom: "0px",
          }}
        >
          Proposal Outputs
        </Typography>
        <FormGroup sx={{ verticalAlign: "top" }}>
          <FormControlLabel
            control={<Checkbox size="small" checked={true} disabled={true} />}
            label={"Detailed Analysis & Impact Report"}
          />
        </FormGroup>
        <FormGroup sx={{ verticalAlign: "top" }}>
          <FormControlLabel
            control={<Checkbox size="small" checked={openPullRequest} onChange={() => handlePullRequest()} />}
            label="Create Pull Request"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}>
        <EdgeBitPrimaryButton onClick={handleStartCategoryAnalysis} disabled={useAppSelector(selectCreatingProposal)}>
          Start Analysis
        </EdgeBitPrimaryButton>
        <Button tabIndex={-1} onClick={handleCategoryModalClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
